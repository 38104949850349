// Here you can add other styles
.margin-btm--10{
  margin-bottom:-10px
}

.data-grid{
  font-size: 0.75rem !important;
}

.data-grid-container .data-grid .cell > input {
  width: 100% !important;
  height: 100% !important;
}

.data-grid-container .data-grid .cell {
  padding: 2px;
}

.data-grid-container .data-grid .cell.read-only {
  /*background: whitesmoke;
  color: #999;
  text-align: center;*/
  font-weight: 600;
  font-size: 0.8rem !important;
}

.data-grid-container .data-grid .cell.bold-cell {
  font-weight: 600;
}

.data-grid-container .data-grid tbody {
  display: table !important;
  width: 100% !important;
}

.td-head{
  padding: 2px !important;
  border-top:none !important;
}

.select-invalid ~ .invalid-feedback{
  display: block;
}
